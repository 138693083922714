import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import "typeface-roboto";
import "typeface-montserrat";
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import {Main, ResponsiveContext} from "grommet";
import Routes from "./Routes";
import {IntlProvider} from "react-intl";
import ErrorProvider from "../context/ErrorProvider";
import BookingProvider from "../context/BookingProvider";
import ErrorModal from "../components/ErrorModal";


const App = () => {
  const size = React.useContext(ResponsiveContext);
  const small = 'xsmall' === size || 'small' === size;
  const background = small ? undefined : {
    image:"url(/background.jpg)",
    repeat: "no-repeat",
    position: "initial",
    size: "100px"
  };
  const [language, setLanguage] = useState('sv');
  const [messages, setMessages] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let isCancelled = false;
    fetch(`/i18n/${language}.json`).then(response => {
      response.json().then((localizedMessages: Record<string,string>) => {
        if (!isCancelled) {
          setMessages(localizedMessages);
          setLoaded(true);
        }
      })
    })
    return () => {
      isCancelled = true;
    };
  }, [language, loaded]);
  if (!loaded) {
    return null;
  } else {
    return (
      <IntlProvider locale={language} key={language} messages={messages}>
        <ErrorProvider>
          <BookingProvider>
            <Main background={background}>
                <Router>
                  <Routes language={language} setLanguage={setLanguage}/>
                </Router>
                <ErrorModal />
            </Main>
          </BookingProvider>
        </ErrorProvider>
      </IntlProvider>
    );
  }
}

export default App;
