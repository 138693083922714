import React, {useEffect, useState} from 'react';
import Login from "../../components/Login";
import {fetchAuthType} from "../../api/auth";
import {AuthType} from "../../api/bookingTypes";
import LoginBankID from "../../components/Login/LoginBankID";
import {pushErrorAction} from "../../hooks/useError/actions";
import {useIntl} from "react-intl";
import useErrorAction from "../../hooks/useError/useErrorAction";
import messages from "./messages";
import {fetchPortalSettings} from "../../api/filter";
import {setPortalSettings} from "../../hooks/useBooking/actions";
import useBookingAction from "../../hooks/useBooking/useBookingAction";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const bookingDispatch = useBookingAction();
  const errorDispatch = useErrorAction();
  const [authType, setAuthType] = useState(AuthType.PLAIN);

  useEffect(() => {
    let isCancelled = false;
    fetchAuthType().then(type => {
      if (!isCancelled) {
        setAuthType(type);
      }
    }).catch(() => {
      errorDispatch(pushErrorAction({
        title: formatMessage(messages.noAuthTypeTitle),
        message: formatMessage(messages.noAuthTypeMessage),
        fixed: true
      }));
    })
    return () => {
      isCancelled = true;
    };
  }, [errorDispatch, formatMessage]);

  useEffect(() => {
    let isCancelled = false;
    fetchPortalSettings().then(settings => {
      if (!isCancelled) {
        bookingDispatch(setPortalSettings(settings));
      }
    }).catch(() => {
      errorDispatch(pushErrorAction({
        title: formatMessage(messages.loadingErrorTitle),
        message: formatMessage(messages.loadingErrorMessage)
      }))
    })
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, formatMessage]);

  return (
      authType === AuthType.FED ? <LoginBankID /> : <Login />
  );
};

export default LoginPage;