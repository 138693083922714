import React from "react";
import {Box, Heading, Image} from "grommet";
import theme from "../../style/theme";

interface Props {
  label?: string;
  button?: JSX.Element;
}

const MobilePageHeader: React.FC<Props> = ({label, button, children}) => {

  return (
    <Box tag='header'>
      <Box
        direction='row'
        height={{min:'64px', max:'64px'}}>
        <Box
          pad={{left: "medium"}}
          fill
          direction='row'
          align={"center"}
          background={theme.custom.menu.background}>
          <Box fill>
            <Heading level={1} color={theme.custom.menu.text}>{label}</Heading>
          </Box>
          {button}
        </Box>
        <Box
          height={{min:'64px', max:'64px'}}
          width={{min: '116px', max: '116px'}}
          align={"center"}
          justify={"center"}
          fill>
          <Image src="/logo.png" height={30} width={90} />
        </Box>
      </Box>
      { children }
    </Box>
  );
};


export default MobilePageHeader;