import styled from "styled-components";
import {Button as GrommetButton} from "grommet";
import React from "react";
import {Omit} from "grommet/utils";
import {ButtonProps} from "grommet/components/Button";
import Spinner from "./Spinner";
import theme from "../../style/theme";

interface Props {
  width?: string;
  height?: string;
  showSpinner?: boolean;
}

const StyledButton = styled(GrommetButton)<Props>`
  & {
    font-weight: 500;
    padding: 4px 0px;
    height: ${({height}) => height || '30px'};
    width: ${({width}) => width || '120px'};
    border-color: ${({secondary, theme}) => secondary && theme.custom.button.secondary.border };
    background: ${({secondary, theme}) => secondary && theme.custom.button.secondary.background };
    color: ${({secondary, theme}) => secondary && theme.custom.button.secondary.text };
    border-color: ${({primary, theme}) => primary && theme.custom.button.primary.border };
    background: ${({primary, theme}) => primary && theme.custom.button.primary.background };
    color: ${({primary, theme}) => primary && theme.custom.button.primary.text };
  }

  & svg {
    fill: ${({secondary, theme}) => secondary && theme.custom.button.secondary.text };
    fill: ${({primary, theme}) => primary && theme.custom.button.primary.text };
  }

  & :focus, :hover {
    box-shadow: ${({secondary, theme}) => secondary && `0 0 1px 1px ${theme.custom.button.secondary.border}` };
    box-shadow: ${({primary, theme}) => primary && `0 0 1px 1px ${theme.custom.button.primary.border}` };
    box-shadow: ${({plain}) => plain && `none` };
  }
`;

const Button: React.FC<Props & ButtonProps & Omit<JSX.IntrinsicElements['button'], 'color'>> = (props) => {
  const spinnerColor = props.secondary ? theme.custom.button.secondary.text : theme.custom.button.primary.text;
  return (
    <StyledButton
      {...props}
      disabled={props.showSpinner || props.disabled}
      icon={props.showSpinner ? <Spinner size={14} color={spinnerColor}/> : undefined}
      label={props.showSpinner ? undefined : props.label}
    />
  );
};

export default Button;