import React, {Dispatch} from "react";
import {BookingAction, BookingState} from "../hooks/useBooking/types";
import {useBooking} from "../hooks/useBooking/useBooking";

export const BookingStateContext = React.createContext<BookingState | undefined>(undefined);
export const BookingActionContext = React.createContext<Dispatch<BookingAction> | undefined>(undefined);

const BookingProvider: React.FC = ({ children }) => {
  const [state, actions] = useBooking();

  return (
    <BookingStateContext.Provider value={state}>
      <BookingActionContext.Provider value={actions}>
        {children}
      </BookingActionContext.Provider>
    </BookingStateContext.Provider>
  );
};

export default BookingProvider;