import axios from 'axios';
import {ApiClient, AuthType} from "./bookingTypes";
import {BankIdStatus, CompleteResponse, InitiateResponse, LoginResponse} from "./authTypes";

export const pnrLogin = async (pnr: string, clientId?: string, bookingId?: string): Promise<LoginResponse> => {
  const result = await axios.post<LoginResponse>('/api/v1/login',
    {
      civicRegistrationNumber: pnr,
      clientId: clientId,
      bookingId: bookingId
    },
    {
      validateStatus: function (status) {
        return status === 200;
      }
    })
  return result.data
};

export const bankIdInitiate = async (civicRegistrationNumber: string, clientId?: string, bookingId?: string): Promise<InitiateResponse> => {
  const response = await axios.post('/api/v1/login',
    {
      civicRegistrationNumber: civicRegistrationNumber,
      clientId: clientId,
      bookingId: bookingId
    },
    {
      validateStatus: function (status) {
        return status === 200;
      }
    });

  const location = response.headers["location"];
  const sessionId = response.headers["x-sessionid"];
  return {
    sessionId: sessionId,
    redirectUrl: location
  }
};

export const bankIdComplete = async (pnr: string, sessionId: string, redirectUri: string, clientId?: string, bookingId?: string): Promise<CompleteResponse> => {
  // Flush uri. Needed for user login.
  await axios.get(redirectUri);

  const result = await axios.post<LoginResponse>('/api/v1/login',
    {
      civicRegistrationNumber: pnr,
      clientId: clientId,
      sessionId: sessionId,
      bookingId: bookingId
    },
    {
      validateStatus: function (status) {
        return status === 200 || status === 202;
      }
    })

  if(result.status === 202) {
    return {
      status: BankIdStatus.PENDING
    }
  } else {
    return {
      patient: result.data.patient,
      companySettings: result.data.company?.settings,
      status: BankIdStatus.OK
    }
  }
};

export const fetchAuthType = async (): Promise<AuthType> => {
  const result = await axios.get('/api/v1/authtype', {
    validateStatus: function (status) {
      return status === 200;
    }
  })
  return result.data
}

export const fetchApiClient = async (): Promise<ApiClient> => {
  const result = await axios.get('/api/v1/me', {
    validateStatus: function (status) {
      return status === 200;
    }
  })
  return result.data
}
