import React from "react";
import {Box, Heading, Image, ResponsiveContext, ThemeContext} from "grommet";
import theme from "../../style/theme";
import styled from "styled-components";
import messages from "./messages";
import {useIntl} from "react-intl";

interface Props {

}

const NotFoundPage = styled(Box)`
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 50px;
  min-height: -webkit-fill-available;
`;

const NotFound: React.FC<Props> = () => {

    const { formatMessage } = useIntl();
    const size = React.useContext(ResponsiveContext);

    return (
        <ThemeContext.Extend
            value={{ global: { colors: { placeholder: theme.global.colors.brand } } }}>
            <NotFoundPage flex>
                <Box css={{flex:1, maxHeight: "120px", minHeight: "55px"}} direction={"row"}>
                    { ('xlarge' === size || 'large' === size || 'medium' === size) && <Image src="/logo.png" height={40} width={120} css={{position:'absolute', top: '20px', right: '35px'}} /> }
                    <Heading
                        alignSelf={"end"}
                        level="3"
                        margin={{vertical:"small"}}>{formatMessage(messages.notFoundMessage)}
                    </Heading>
                </Box>
            </NotFoundPage>
        </ThemeContext.Extend>
    );
};

export default NotFound;