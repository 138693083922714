import React, { useEffect, useState } from "react";
import { Box, Heading, ThemeContext } from "grommet";
import messages from "./messages";
import { useIntl } from "react-intl";
import useBookingState from "../../hooks/useBooking/useBookingState";
import useBookingAction from "../../hooks/useBooking/useBookingAction";
import {setAvailableSlots, setSearchFilter} from "../../hooks/useBooking/actions";
import {SearchFilter, SelectOption, SelectServiceOption} from "../../hooks/useBooking/types";
import { fetchAvailableSlots } from "../../api/booking";

import SearchFormField from "./FormFields/SearchFormField";
import SearchSelect from "./FormFields/SearchSelect";
import theme from "../../style/theme";
import Button from "../Common/Button";
import moment from "moment";
import SearchDateInput from "./FormFields/SearchDateInput";
import useErrorAction from "../../hooks/useError/useErrorAction";
import { pushErrorAction } from "../../hooks/useError/actions";

interface Props {}



const Search: React.FC<Props> = () => {
  const { formatMessage, locale } = useIntl();
  const { booking, filter, careUnits, services, portalSettings } = useBookingState();
  const bookingDispatch = useBookingAction();
  const [loading, setLoading] = useState(false);
  const errorDispatch = useErrorAction();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleSubmit = async (event: any) => {

    setLoading(true);
    fetchAvailableSlots(filter).then(grouped => {
      bookingDispatch(setAvailableSlots(grouped));
      setLoading(false);
    }).catch(() => {
        errorDispatch(pushErrorAction({
            title: formatMessage(messages.unkownError),
            message: formatMessage(messages.unkownErrorMessage),
            fixed: false
        }));
        setLoading(false);
    });
  };

  const updateFilter = (newFilter: SearchFilter) => {
    bookingDispatch(setSearchFilter(newFilter))
  }

  const currentStartDate = (filter.startDate || moment()).format("yyyy-MM-DD");
  const searchSpan = portalSettings?.defaultDateRange || 3;
  const currentEndDate = (filter.endDate || moment(currentStartDate).add(searchSpan, "days")).format("yyyy-MM-DD");
  const minDate = moment().format("yyyy-MM-DD");
  const maxDate = moment().add(1, "year").format("yyyy-MM-DD");

   return (
    <ThemeContext.Extend
      value={{
        global: {
          colors: {
            placeholder: theme.custom.menu.placeholder
          }
        }
      }}>
      <Box
        flex
        width="medium"
        direction="column">
        <Box css={{flex:1, height:"120px", maxHeight: "120px", minHeight: "60px"}} direction={"row"}>
          <Heading
            alignSelf={"end"}
            level="1"
            margin={{left:"5px", vertical:"small"}}
            color={theme.custom.menu.text}>{formatMessage(messages.header)}</Heading>
        </Box>
        <SearchFormField label={formatMessage(messages.type)}>
          <SearchSelect
              isMulti={false}
              isClearable={false}
              isDisabled={!!booking}
              value={filter.service}
              onChange={ selectedService  => {
                  updateFilter({ ...filter, service: selectedService as SelectServiceOption});
              }}
              options={services.map(o => ({ ...o, value: o.id + o.subContractId, label: o.name, duration: o.realTimeSpan}))}
              noOptionsMessage={formatMessage(messages.typeNoOptions)}
              placeholder={formatMessage(messages.servicePlaceholder)}
          />

        </SearchFormField>
        <SearchFormField label={formatMessage(messages.careUnit)}>
          <SearchSelect
            isMulti
            value={filter.careUnits}
            onChange={ selected => {
              const value = Array.isArray(selected) ? selected : [];
              updateFilter({ ...filter, careUnits: value});
            }}
            options={careUnits.map(o => ({ value: o.id, label: o.name}))}
            noOptionsMessage={formatMessage(messages.careUnitNoOptions)}
            placeholder={formatMessage(messages.careUnitPlaceholder)}
          />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.startDate)}>
          <SearchDateInput
            small={window.innerHeight < 870}
            value={currentStartDate}
            locale={locale}
            bounds={[minDate, maxDate]}
            placeholder={formatMessage(messages.datePlaceholder)}
            onChange={(value) => {
              const startDate = moment(value);
              if (startDate.isAfter(moment(currentEndDate).endOf("day").subtract(searchSpan, "days"))) {
                const endDate = moment(startDate).add(searchSpan, "days");
                updateFilter({ ...filter, startDate: startDate, endDate: endDate})
              } else {
                updateFilter({ ...filter, startDate: startDate});
              }
            }} />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.endDate)}>
          <SearchDateInput
            small={window.innerHeight < 870}
            value={currentEndDate}
            locale={locale}
            bounds={[currentStartDate, maxDate]}
            placeholder={formatMessage(messages.datePlaceholder)}
            onChange={(value) => updateFilter({ ...filter, endDate:  moment(value)})} />
        </SearchFormField>
        <SearchFormField label={formatMessage(messages.earliestStartAt)}>
          <SearchSelect
            isClearable={true}
            isSearchable={false}
            value={filter.earliestStartAt}
            menuPlacement={window.innerHeight > 870 ? "auto":"top"}
            onChange={ selected => {
              const value = selected === null || Array.isArray(selected) ? undefined : selected as SelectOption;
              updateFilter({ ...filter, earliestStartAt: value});
            }}
            options={portalSettings.earliestStartAtHours}
            noOptionsMessage={formatMessage(messages.earliestStartAtNoOptions)}
            placeholder={formatMessage(messages.earliestStartAtPlaceholder)}
          />
        </SearchFormField>
        <Box css={{flexGrow:1, flexShrink:1, height: "300px", minHeight: "50px"}} direction="row" justify="center" pad={{vertical: "medium"}}>
          <Button
            disabled={filter.service === undefined}
            width="160px"
            label={formatMessage(messages.button)}
            primary
            showSpinner={loading}
            onClick={handleSubmit} />
        </Box>
      </Box>
    </ThemeContext.Extend>
  );
};

export default Search;