import React, {FC, useEffect, useState} from 'react';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import BookPage from "../pages/BookPage";
import useBookingState from "../hooks/useBooking/useBookingState";
import useBookingAction from "../hooks/useBooking/useBookingAction";
import {clearPatient, setBookingId, setClientId} from "../hooks/useBooking/actions";
import ConfirmationPage from "../pages/ConfirmationPage";
import "typeface-roboto";
import "typeface-montserrat";
import moment from "moment";
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import LoginPage from "../pages/LoginPage";
import axios from "axios";
import NotFound from "../components/Login/NotFound";

interface Props {
  setLanguage: (language: string) => void;
  language: string;
}

interface ExtendedRouteProps {
  isAuthenticated: boolean;
}

const LoginRoute: FC<ExtendedRouteProps & RouteProps> = ({ children, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticated ? (
        <Redirect
          to={{
            pathname: '/',
            search: location ? location.search : "",
            state: { from: location },
          }}
        />
      ) : (
        children
      )
    }
  />
);

const PrivateRoute: FC<ExtendedRouteProps & RouteProps> = ({ children, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: location ? location.search : "",
            state: { from: location },
          }}
        />
      )
    }
  />
);

const Routes = ({setLanguage, language}: Props) => {
  moment.locale(language);
  const { patientDetails } = useBookingState();
  const bookingDispatch = useBookingAction();
  const [loadedClientId, setLoadedClientId] = useState(false);
  const [loadedBookingId, setLoadedBookingId] = useState(false);
  const [loadedInterceptor, setLoadedInterceptor] = useState(false);
  const isAuthenticated = !!patientDetails?.civicRegNumber;
  const location = useLocation();

  useEffect(() => {
    let isCancelled = false;
    const path = location.pathname;
    const pathArr = path.split('/').filter(s => s !== '');
    if (pathArr.length === 2 && pathArr[0] === 'book') {
      const bookingId = pathArr[1];
      if (bookingId && !isCancelled) {
        bookingDispatch(setBookingId(bookingId));
      }
    }
    setLoadedBookingId(true);
    return () => {
      isCancelled = true;
    };
  }, [location, bookingDispatch]);

  useEffect(() => {
    let isCancelled = false;
    const client_id = new URLSearchParams(location.search).get('client_no')
    if (client_id && !isCancelled) {
      bookingDispatch(setClientId(client_id))
    }
    setLoadedClientId(true);
    return () => {
      isCancelled = true;
    };
  }, [location, bookingDispatch]);

  useEffect(() => {
    let isCancelled = false;
    axios.interceptors.response.use(
      response => {
        if (!isCancelled && response.status === 401) {
          bookingDispatch(clearPatient())
        }
        return response
      },
      error => {
        if (!isCancelled && error.response.status === 401) {
          bookingDispatch(clearPatient())
        }
        return Promise.reject(error)
      }
    )
    setLoadedInterceptor(true);
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch])

  if (!(loadedClientId && loadedInterceptor && loadedBookingId)) {
    return null;
  }
  return (<Switch>
    <LoginRoute path="/login" isAuthenticated={isAuthenticated}>
      <LoginPage />
    </LoginRoute>
    <PrivateRoute path="/book/:id" isAuthenticated={isAuthenticated}>
      <ConfirmationPage />
    </PrivateRoute>
    <PrivateRoute path="/book" isAuthenticated={isAuthenticated}>
      <BookPage />
    </PrivateRoute>
    <Route exact path="/">
      <Redirect to={{
        pathname: "/book",
        search: location ? location.search : "",
      }} />
    </Route>
    <Route path="*" component={NotFound} />
  </Switch>);
}

export default Routes;
