import React from "react";
import { Box, Heading, Image } from "grommet";
import styled from "styled-components";

const HeadingWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.custom.header.border};
  margin-bottom: 25px;
  margin-top: 33px;
  min-height: 55px;
  color: ${({ theme }) => theme.custom.header.text};
`;

interface Props {
  label: string;
  showIcon?: boolean;
}

const PageHeader: React.FC<Props> = ({ label, showIcon }) => {
  return (
    <HeadingWrapper direction="row" align="center">
      <Box fill justify={"end"}>
        <Heading margin={{ horizontal: "0px", vertical: "5px" }} level="1">
          {label}
        </Heading>
      </Box>
      {showIcon && <Image src="/logo.png" height={40} width={120} />}
    </HeadingWrapper>
  );
};

export default PageHeader;
