import React, {FC} from "react";
import { Box } from "grommet";
import theme from "../../style/theme";
import {CircleSpinner} from "react-spinners-kit";

interface Props {
  color?: string
  size?: number
}

export const Spinner: FC<Props> = ({color, size}) => (
  <Box align="center" justify="center">
    <CircleSpinner
      color={color || theme.global.colors.brand}
      size={size || 28}
    />
  </Box>
);

export default Spinner;