import axios from "axios";
import {CareUnit, Service} from "./bookingTypes";
import { ApiPortalSettings, PortalSettings } from "./portalSettingsTypes";


export const fetchCareUnits = async (): Promise<CareUnit[]> => {
  const results = await axios.get('/api/v1/careunit', {
    validateStatus: function (status) {
      return status === 200;
    }
  })
  return results.data
};

export const fetchServices = async (clientId: string | undefined): Promise<Service[]> => {
  const results = await axios.get('/api/v1/service/' + clientId, {
    validateStatus: function (status) {
      return status === 200;
    }
  })
  return results.data
};

export const fetchPortalSettings = async (): Promise<PortalSettings> => {
  const results = await axios.get<ApiPortalSettings>('/api/v1/settings', {
    validateStatus: function (status) {
      return status === 200;
    }
  })
  const hours = results.data.hours.map((value, index) => ({label: value, value: index}))
  return {
    earliestStartAtHours: hours,
    defaultDateRange: results.data.defaultDateRange
  }
};