import ReactSelect, {components} from 'react-select';
import styled from 'styled-components';
import React from "react";
import {SelectOption, SelectServiceOption} from "../../../hooks/useBooking/types";
import IndicatorUp from '../../../resources/img/icon-arrow-up-white.png'
import IndicatorDown from '../../../resources/img/icon-arrow-down-white.png'
import {Image} from "grommet";

const CustomSelect = styled(ReactSelect)`
  & .Select__control {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    background: ${({theme}) => theme.custom.menu.background};
    color: ${({theme}) => theme.custom.menu.text};
  }
  & .Select__placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;  
    color: ${({theme}) => theme.global.colors.placeholder};
  }
  & .Select__menu {
    border-radius: 0px;
    margin: 0px;
  }
  & .Select__multi-value {
    border-radius: 2px;
    border: 1px solid ${({theme}) => theme.custom.menu.text};
  }
  & .Select__multi-value__label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border-radius: 0px;
    background: ${({theme}) => theme.custom.menu.background};
    color: ${({theme}) => theme.custom.menu.text};
  }
  & .Select__single-value {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    border-radius: 0px;
    background: ${({theme}) => theme.custom.menu.background};
    color: ${({theme}) => theme.custom.menu.text};
  }
  & .Select__indicator-separator {
    background-color: ${({theme}) => theme.custom.menu.background};
  }
  & .Select__indicator {
    padding: 9px;
    color: ${({theme}) => theme.custom.menu.text};
  }
  
  & .Select__multi-value__remove {
    border-radius: 0px;
    background: ${({theme}) => theme.custom.menu.background};
    color: ${({theme}) => theme.custom.menu.text};
  }
  & .Select__menu {
    color:  ${({theme}) => theme.custom.menu.background};
  }
  & .Select__option--is-focused {
    background: rgba(221,221,221,0.4);
  }
  & .Select__option--is-selected {
    background: ${({theme}) => theme.global.colors.brand};
  }
  & .Select__input {
    color: ${({theme}) => theme.custom.menu.text};
  }
`;


interface Props {
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuPlacement?: string;
  value?: SelectOption | SelectOption[] | SelectServiceOption | null;
  onChange: (selected?: SelectOption | SelectOption[] | SelectServiceOption | null) => void;
  options: SelectOption[] | SelectServiceOption[];
  placeholder?: string;
  noOptionsMessage?: string;
}

// @ts-ignore
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Image width={"16px"} height={"15px"} src={props.selectProps.menuIsOpen ? IndicatorUp : IndicatorDown} hidden={props.isDisabled}/>
      </components.DropdownIndicator>
    )
  );
};

const SearchSelect: React.FC<Props> = (props) => {
  return (
    <CustomSelect
      components={{ DropdownIndicator }}
      classNamePrefix={'Select'}
      {...props}
      noOptionsMessage={props.noOptionsMessage === undefined ? undefined : (() => {return props.noOptionsMessage}) }
    />
  );
};

export default SearchSelect;