import styled from "styled-components";
import {Box, CheckBox as GrommetCheckBox, CheckBoxProps} from "grommet";
import React from "react";
import theme from "../../style/theme";

interface Props {
  error?: boolean;
}

const Wrapper = styled(Box)<Props>`
  margin: 10px 0;
  color: ${({error}) => error && theme.custom.error.text};
  & > label > div > div {
    border-color: ${({error}) => error && theme.custom.error.text};
  }
`;

const CheckBox: React.FC<Props & CheckBoxProps & JSX.IntrinsicElements['input']> = (props) => {
  return (
    <Wrapper error={props.error}>
      <GrommetCheckBox {...props} />
    </Wrapper>
  );
};

export default CheckBox;