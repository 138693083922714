import React, { Fragment, useEffect, useState } from "react";
import { Box, Header, Layer, ResponsiveContext } from "grommet";
import { FormSearch } from "grommet-icons";
import Search from "../../components/Search";
import Result from "../../components/Result";
import PatientForm from "../../components/PatientForm";
import useBookingState from "../../hooks/useBooking/useBookingState";
import useBookingAction from "../../hooks/useBooking/useBookingAction";
import {
  setApiClient,
  setCareUnits,
  setPage,
  setServices
} from "../../hooks/useBooking/actions";
import theme from "../../style/theme";
import { BookingPage } from "../../hooks/useBooking/types";
import Confirmation from "../../components/Confirmation";
import Button from "../../components/Common/Button";
import EditBookingHeader from "../../components/EditBookingHeader";
import {fetchCareUnits, fetchServices} from "../../api/filter";
import { useIntl } from "react-intl";
import messages from "./messages";
import MobilePageHeader from "../../components/Common/MobilePageHeader";
import { fetchApiClient } from "../../api/auth";
import useErrorAction from "../../hooks/useError/useErrorAction";
import { pushErrorAction } from "../../hooks/useError/actions";

interface Props {}

const BookPage: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { page, booking, clientId, apiClient } = useBookingState()
  const bookingDispatch = useBookingAction();
  const errorDispatch = useErrorAction();
  const [loadedCareUnits, setLoadedCareUnits] = useState(false);
  const [loadedServices, setLoadedServices] = useState(false);
  const [loadedApiClient, setLoadedApiClient] = useState(false);

  const showSearch = () => {
    bookingDispatch(setPage(BookingPage.SEARCH_SLOTS))
  };

  const renderSearchPage = () => {
    return (<ResponsiveContext.Consumer>
      {size => (
        <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
          { size === 'xsmall' || size === 'small' ? renderCollapsingSearch() : renderFixedSearch()}
        </Box>
      )}
    </ResponsiveContext.Consumer>);
  };

  const renderFixedSearch = () => {
    return (
      <Box fill direction={"row"} overflow={"hidden"}>
        <Box
          height={{min: "100vh"}}
          width={{min: "450px"}}
          background={theme.custom.menu.background}
          color={theme.custom.menu.text}
          elevation='small'
          align='center'
          overflow={"auto"}
        >
          <Search />
        </Box>
        <Box direction="column" fill={"horizontal"}>
          { booking &&
          <Header>
            <EditBookingHeader />
          </Header>
          }
          <Result />
        </Box>
      </Box>
    );
  };

  const renderCollapsingSearch = () => {
    return (
      <Fragment>
        {page === BookingPage.SELECT_SLOT && (
          <Layer>
            <MobilePageHeader
              label={formatMessage(messages.header)}
              button={
                <Button
                  width="30px"
                  margin={{ right: "5px" }}
                  plain
                  onClick={showSearch}>
                  <FormSearch />
                </Button>
              }>
              {booking && <EditBookingHeader />}
            </MobilePageHeader>
            <Box flex align="center" justify="center">
              <Result />
            </Box>
          </Layer>
        )}
        <Box
          height={{ min: "100%" }}
          fill
          background={theme.custom.menu.background}
          color={theme.custom.menu.text}
          align="center"
          overflow={"auto"}>
          <Search />
        </Box>
      </Fragment>
    );
  };

  const renderPatientDetailsPage = () => {
    return (<PatientForm />);
  };

  const renderBookPage = () => {
    return (<Confirmation />);
  };

  const renderPage = (p: BookingPage) => {
    switch (p as BookingPage) {
      case BookingPage.SEARCH_SLOTS:
      case BookingPage.SELECT_SLOT:
        return renderSearchPage();
      case BookingPage.PATIENT_DETAILS:
        return renderPatientDetailsPage();
      case BookingPage.BOOK:
        return renderBookPage();
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (apiClient) {
      setLoadedApiClient(true);
    } else {
      fetchApiClient().then(result => {
        if (!isCancelled) {
          bookingDispatch(setApiClient(result))
          setLoadedApiClient(true);
        }
      }).catch(reason => {
        errorDispatch(pushErrorAction({
          title: formatMessage(messages.loadingErrorTitle),
          message: formatMessage(messages.loadingErrorMessage)
        }))
      })
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, apiClient, formatMessage]);

  useEffect(() => {
    let isCancelled = false;
    fetchCareUnits().then(careUnits => {
      if (!isCancelled) {
        bookingDispatch(setCareUnits(careUnits));
        setLoadedCareUnits(true);
      }
    }).catch(reason => {
      errorDispatch(pushErrorAction({
        title: formatMessage(messages.loadingErrorTitle),
        message: formatMessage(messages.loadingErrorMessage)
      }))
    })
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, formatMessage]);

  useEffect(() => {
    let isCancelled = false;
    fetchServices(clientId).then(services => {
      if (!isCancelled) {
        bookingDispatch(setServices(services));
        setLoadedServices(true);
      }
    }).catch(reason => {
      errorDispatch(pushErrorAction({
        title: formatMessage(messages.loadingErrorTitle),
        message: formatMessage(messages.loadingErrorMessage)
      }))
    })
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, formatMessage, clientId]);

  if (!(loadedCareUnits && loadedServices && loadedApiClient)) {
    return null;
  }

  return renderPage(page);
};

export default BookPage;