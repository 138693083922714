import React, { Dispatch } from 'react';
import { ErrorAction, ErrorState } from '../hooks/useError/types';
import { useError } from '../hooks/useError/useError';

export const ErrorStateContext = React.createContext<ErrorState | undefined>(undefined);
export const ErrorActionContext = React.createContext<Dispatch<ErrorAction> | undefined>(undefined);

const ErrorProvider: React.FC = ({ children }) => {
  const [state, actions] = useError();

  return (
    <ErrorStateContext.Provider value={state}>
      <ErrorActionContext.Provider value={actions}>{children}</ErrorActionContext.Provider>
    </ErrorStateContext.Provider>
  );
};

export default ErrorProvider;
