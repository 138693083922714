import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useBookingAction from "../../hooks/useBooking/useBookingAction";
import {
  setApiClient,
  setBooking,
  setSearchFilter
} from "../../hooks/useBooking/actions";
import Confirmation from "../../components/Confirmation";
import {fetchBooking} from "../../api/booking";
import {pushErrorAction} from "../../hooks/useError/actions";
import useErrorAction from "../../hooks/useError/useErrorAction";
import {useIntl} from "react-intl";
import messages from "./messages";
import {fetchApiClient} from "../../api/auth";
import useBookingState from "../../hooks/useBooking/useBookingState";
import {SearchFilter} from "../../hooks/useBooking/types";
import {fetchServices} from "../../api/filter";

interface Props {}

const ConfirmationPage: React.FC<Props> = () => {
  const { id } = useParams();
  const errorDispatch = useErrorAction();
  const bookingDispatch = useBookingAction();
  const { apiClient, booking, filter, clientId} = useBookingState();
  const { formatMessage } = useIntl();
  const [loadedBooking, setLoadedBooking] = useState(false);
  const [loadedApiClient, setLoadedApiClient] = useState(false);

  useEffect(() => {

    let isCancelled = false;
    if (booking) {
      setLoadedBooking(true)
    } else {
      if (id === "confirmation") {
        errorDispatch(pushErrorAction({
          title: formatMessage(messages.bookingNotFoundTitle),
          message: formatMessage(messages.bookingNotFoundMessage)
        }));
      } else {
        fetchBooking(id).then(bookingData => {
          if (!isCancelled) {
            let service = {
              ...bookingData.mainService,
              value: bookingData.mainService.id + bookingData.mainService.subContractId,
              label: bookingData.mainService.name,
              realTimeSpan: bookingData.mainService.duration
            };

            if (filter.service === undefined) {
              fetchServices(clientId).then(services => {
                const contractServicesMatchingBookingService = services.filter(service =>
                    service.subContractId === bookingData.mainService.subContractId &&
                    service.id === bookingData.mainService.id);
                if (contractServicesMatchingBookingService.length > 0) {
                  const contractService = contractServicesMatchingBookingService[0];
                  service = {...contractService,
                    value: contractService.id + contractService.subContractId,
                    label: contractService.name,
                    duration: contractService.realTimeSpan};
                }
              }).catch(() => {
                errorDispatch(pushErrorAction({
                  title: formatMessage(messages.loadingErrorTitle),
                  message: formatMessage(messages.loadingErrorMessage)
                }))
              });
            }


            const newFilter: SearchFilter = {
              ...filter,
              service: service,
              careUnits: [{
                value: bookingData.careUnit.id,
                label: bookingData.careUnit.name
              }]
            };

            bookingDispatch(setSearchFilter(newFilter));
            bookingDispatch(setBooking(bookingData));

            setLoadedBooking(true);
          }
        }).catch(() => {
          errorDispatch(pushErrorAction({
            title: formatMessage(messages.bookingNotFoundTitle),
            message: formatMessage(messages.bookingNotFoundMessage)
          }));
        });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, id, errorDispatch, formatMessage, booking, filter]);

  useEffect(() => {
    let isCancelled = false;
    if (apiClient) {
      setLoadedApiClient(true);
    } else {
      fetchApiClient().then(result => {
        if (!isCancelled) {
          bookingDispatch(setApiClient(result))
          setLoadedApiClient(true);
        }
      }).catch(() => {
        errorDispatch(pushErrorAction({
          title: formatMessage(messages.loadingErrorTitle),
          message: formatMessage(messages.loadingErrorMessage)
        }))
      })
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingDispatch, errorDispatch, formatMessage, apiClient]);

  if (!loadedBooking || !loadedApiClient) {
    return null;
  }

  return <Confirmation />;
};

export default ConfirmationPage;