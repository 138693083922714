import {
  PushErrorAction,
  PopErrorAction,
  Error,
  ErrorTypeKeys
} from './types';

export const pushErrorAction = (error: Error): PushErrorAction => ({
  type: ErrorTypeKeys.PUSH_ERROR,
  error,
});
export const popErrorAction = (): PopErrorAction => ({
  type: ErrorTypeKeys.POP_ERROR,
});
