import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Heading, ResponsiveContext, Text } from "grommet";
import messages from "./messages";
import useBookingAction from "../../hooks/useBooking/useBookingAction";
import { clearBooking, setAvailableSlots, setPage, setSearchFilter } from "../../hooks/useBooking/actions";
import { BookingPage } from "../../hooks/useBooking/types";
import useBookingState from "../../hooks/useBooking/useBookingState";
import SlotComponent from "../Common/SlotComponent";
import Button from "../Common/Button";
import { useHistory, useLocation } from "react-router-dom";
import CancelBooking from "../CancelBooking";
import CancelConfirmation from "../CancelConfirmation";
import { cancelBooking, fetchAvailableSlots } from "../../api/booking";
import PageContainer from "../Common/PageContainer";
import moment from "moment";
import useErrorAction from "../../hooks/useError/useErrorAction";
import { pushErrorAction } from "../../hooks/useError/actions";
import { FieldName } from "../../api/bookingTypes";
import { ErrorType } from "../../api/errorTypes";

interface Props {

}

const Confirmation: React.FC<Props> = () => {
  const bookingDispatch = useBookingAction();
  const errorDispatch = useErrorAction();
  const { formatMessage } = useIntl();
  const { apiClient, filter, booking, extraFieldResults, companySettings } = useBookingState();
  const history = useHistory();
  const location = useLocation();
  const [showCancel, setShowCancel] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [fetchingSlotsNew, setFetchingSlotsNew] = useState(false);
  const [fetchingSlotsEdit, setFetchingSlotsEdit] = useState(false);
  const size = React.useContext(ResponsiveContext);
  const small = 'xsmall' === size;
  const width = (small ? 360 : 600) + "px";
  const buttonWidth = small ? "200px": "100px";

  const onCancelBooking = () => {

    if (!(booking && booking.id)) {
      return;
    }
    cancelBooking(booking.id).then(_ => {
      setShowCancel(false);
      setShowCancelConfirmation(true);
    }).catch(reason => {
      let message;

      let error = reason?.response?.data["message"]
      switch (error) {
        case ErrorType.BOOKING_NOT_FOUND:
          message = messages.bookingMissing;
          break;
        default:
          message = messages.errorCancelFailedMessage;
      }
      errorDispatch(pushErrorAction({
        title: formatMessage(messages.errorCancelFailedTitle),
        message: formatMessage(message)
      }))
    })
  };
  const onEditBooking = () => {

    setFetchingSlotsEdit(true);
    fetchAvailableSlots(filter).then(grouped => {
      bookingDispatch(setAvailableSlots(grouped));
      bookingDispatch(setSearchFilter(filter));
      bookingDispatch(setPage(BookingPage.SEARCH_SLOTS));
      history.push("/book" + (location.search || "" ));
      setFetchingSlotsEdit(false);
    });
  };

  const onBookNew = () => {
    setFetchingSlotsNew(true);
    fetchAvailableSlots(filter).then(grouped => {
      bookingDispatch(setAvailableSlots(grouped));
      bookingDispatch(clearBooking())
      history.push("/book" + (location.search || "" ))
      setFetchingSlotsNew(false);
    });
  };

  if (!booking || !apiClient) {
    return null;
  }
  const minHours = (companySettings?.bookingCancelHoursBefore != null) ? companySettings.bookingCancelHoursBefore : apiClient.bookingCancelHoursBefore;

  const canBeCancelled = moment().add(minHours, "hours").isBefore(moment(booking.startTime));
  const patientId = booking?.patient?.id;

  return (
    <PageContainer label={formatMessage(messages.header)} width={width}>
      <Box direction="row" wrap={true} fill="horizontal">
        <Box flex width={{min:"auto"}}>
          <Heading margin="none" level="3">
            {formatMessage(messages.confirmation)}
          </Heading>
        </Box>
      </Box>
      <Box pad={{top: "30px", bottom:"10px"}}>
        <Text>{formatMessage(messages.bookingBoxHeader)} {booking.patient.firstName} {booking.patient.lastName} </Text>
      </Box>
      <SlotComponent
        small={small}
        key={"slot_"+booking.id}
        video={booking.videoConsultation}
        serviceName={booking.mainService.name}
        careUnit={booking.careUnit}
        resource={booking.resource}
        startTime={booking.startTime} />
      <Box pad={{top:"25px"}}>
        <Text textAlign={small ? "center" : "start"}>
          {booking.videoConsultation
              ? formatMessage(messages.informationEmailVideo)
              : formatMessage(messages.informationEmail)
          }
          <Text weight={"bold"}>
            {extraFieldResults?.find(cm => cm.key === FieldName.email)?.value}
          </Text>
        </Text>
      </Box>
      <Box pad={{top:"25px"}}>
        <Text weight={"bold"} textAlign={small ? "center" : "start"}>
          {(patientId) ? formatMessage(messages.informationLink) : formatMessage(messages.informationNoLink)}
        </Text>
      </Box>
      { booking.id &&
      <Box direction="row" wrap={true} pad={{vertical:"25px"}} justify={small ? "center" : "between"}>
        <Box>
          <Button
            disabled={patientId == null}
            width={buttonWidth}
            margin={{vertical:"5px"}}
            fill
            label={formatMessage(messages.buttonEdit)}
            secondary
            type="button"
            showSpinner={fetchingSlotsEdit}
            onClick={() => {
              if (canBeCancelled) {
                onEditBooking();
              } else {
                errorDispatch(pushErrorAction({
                  title: formatMessage(messages.errorEditNotAllowedTitle),
                  message: formatMessage(
                    messages.errorEditNotAllowedMessage, {hours: minHours}),
                }))
              }
            }}
          />
        </Box>
        <Box>
          <Button
            disabled={patientId == null}
            width={buttonWidth}
            margin={{vertical:"5px"}}
            fill
            label={formatMessage(messages.buttonCancel)}
            secondary
            type="button"
            onClick={() => {
              if (canBeCancelled) {
                setShowCancel(true)
              } else {
                errorDispatch(pushErrorAction({
                  title: formatMessage(messages.errorCancelNotAllowedTitle),
                  message: formatMessage(
                    messages.errorCancelNotAllowedMessage, {hours: minHours}),
                }))
              }
            }}
          />
        </Box>
        <Box>
          <Button
            width={buttonWidth}
            margin={{vertical:"5px"}}
            fill
            label={formatMessage(messages.buttonNew)}
            secondary
            type="button"
            showSpinner={fetchingSlotsNew}
            onClick={onBookNew}/>
        </Box>
      </Box>
      }
      { showCancel &&
      <CancelBooking
        cancel={() => setShowCancel(false)}
        confirm={onCancelBooking}/>
      }
      { showCancelConfirmation &&
      <CancelConfirmation
        dismiss={() => {
          bookingDispatch(clearBooking())
          history.push("/book" + (location.search || "" ))
        }}/>
      }
    </PageContainer>

  );
};

export default Confirmation;