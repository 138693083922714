import {
  BookingPage,
  BookingTypeKeys,
  ClearBookingAction,
  ClearPatientAction,
  ClearSearchFilterAction,
  SearchFilter,
  SetApiClientAction,
  SetAvailableSlotsAction,
  SetBookingAction,
  SetBookingIdAction,
  SetCareUnitsAction,
  SetClientIdAction,
  SetCompanySettingsAction,
  SetExtraFieldResultsAction,
  SetPageAction,
  SetPatientAction,
  SetPortalSettingsAction,
  SetSearchFilterAction,
  SetSelectedSlotAction,
  SetServicesAction,
} from "./types";
import {
  ApiClient,
  Booking,
  CareUnit,
  CompanySettings,
  ExtraFieldResult,
  GroupedSlots,
  Patient,
  Slot,
  Service,
} from "../../api/bookingTypes";
import { PortalSettings } from "../../api/portalSettingsTypes";

export const setApiClient = (apiClient: ApiClient): SetApiClientAction => ({
  type: BookingTypeKeys.SET_API_CLIENT,
  apiClient,
});

export const setClientId = (clientId: string) : SetClientIdAction => ({
  type: BookingTypeKeys.SET_CLIENT_ID,
  clientId,
});

export const setBookingId = (bookingId: string) : SetBookingIdAction => ({
  type: BookingTypeKeys.SET_BOOKING_ID,
  bookingId,
});

export const setCareUnits = (careUnits: CareUnit[]) : SetCareUnitsAction => ({
  type: BookingTypeKeys.SET_CARE_UNITS,
  careUnits,
});

export const setServices = (services: Service[]) : SetServicesAction => ({
  type: BookingTypeKeys.SET_SERVICES,
  services,
});

export const setPortalSettings = (portalSettings: PortalSettings) : SetPortalSettingsAction => ({
  type: BookingTypeKeys.SET_PORTAL_SETTINGS,
  portalSettings,
});

export const setPatient = (patient: Patient) : SetPatientAction => ({
  type: BookingTypeKeys.SET_PATIENT,
  patient,
});

export const clearPatient = () : ClearPatientAction => ({
  type: BookingTypeKeys.CLEAR_PATIENT,
});

export const setPage = (page: BookingPage) : SetPageAction => ({
  type: BookingTypeKeys.SET_PAGE,
  page,
});

export const setSearchFilter = (filter: SearchFilter) : SetSearchFilterAction => ({
  type: BookingTypeKeys.SET_SEARCH_FILTER,
  filter,
});

export const clearSearchFilter = () : ClearSearchFilterAction => ({
  type: BookingTypeKeys.CLEAR_SEARCH_FILTER,
});

export const setAvailableSlots = (groupedSlots: GroupedSlots) : SetAvailableSlotsAction => ({
  type: BookingTypeKeys.SET_AVAILABLE_SLOTS,
  groupedSlots,
});

export const setSelectedSlot = (slot: Slot) : SetSelectedSlotAction => ({
  type: BookingTypeKeys.SET_SELECTED_SLOT,
  slot,
});

export const setBooking = (booking: Booking) : SetBookingAction => ({
  type: BookingTypeKeys.SET_BOOKING,
  booking,
});

export const setCompanySettings = (companySettings: CompanySettings) : SetCompanySettingsAction => ({
  type: BookingTypeKeys.SET_COMPANY_SETTINGS,
  companySettings,
});

export const setExtraFieldResults = (extraFieldResults: ExtraFieldResult[]) : SetExtraFieldResultsAction => ({
  type: BookingTypeKeys.SET_EXTRA_FIELD_RESULTS,
  extraFieldResults,
});

export const clearBooking = () : ClearBookingAction => ({
  type: BookingTypeKeys.CLEAR_BOOKING,
});
