export interface PostBooking {
  id?: number;
  startTime: string;
  endTime: string;
  patient: Patient;
  company: Company;
  resource: Identifier;
  careUnit: Identifier;
  bookingType?: Identifier;
  notifications?: Notification[];
  extraFields: ExtraFieldResult[];
  videoConsultation: boolean;
  mainService: BookingService;
}

export interface Notification {
  type: NotificationType;
  value?: string;
  sendConfirmation: boolean;
  sendReminder: boolean;
}

export interface EditBooking {
  booking: {
    startTime: string;
    endTime: string;
    resource: Identifier;
    mainService: BookingService
  },
  newSlot: Slot
}

export interface ExtraFieldResult {
  key: string;
  value?: string;
}

export interface Identifier {
  id: number;
}

export interface Booking {
  id?: string;
  startTime: string;
  endTime: string;
  patient: Patient;
  company: Company;
  resource: Resource;
  serviceType: ServiceType;
  careUnit: CareUnit;
  mainService: BookingService;
  patientFee: BookingService;
  additionalServices: BookingService[];
  bookingType: BookingType;
  notifications?: Notification[];
  extraFields?: ExtraFieldResult[];
  videoConsultation: boolean;
}

export interface BookingType {
  id: number;
  name: string;
  visible: boolean;
}

export interface BookingService {
  id: string;
  name: string;
  paymentMethod?: PaymentMethod;
  subContractId: string;
  duration: number;
}

export interface Patient {
  id?: string;
  civicRegNumber: string;
  firstName?: string;
  lastName?: string;
  contactMethods?: ContactMethod[];
  protectedIdentity?: boolean;
  smsConsent?: boolean;
  companies: Company[];
  streetAddress?: string;
  zipCode?: string;
  city?: string;
}

export interface ContactMethod {
  type: ContactMethodType;
  value?: string;
}

export interface Company {
  id?: number;
  name?: string;
  customerNumber?: string;
  responsibleCareUnitId?: number;
  settings?: CompanySettings;
}

export interface CompanySettings {
  extraFields: ExtraField[];
  isSmsNotificationsEnabled: boolean;
  bookingCancelHoursBefore?: number;
  bookingSmsReminderHoursBefore?: number;
}

export interface ExtraField {
  id: string;
  name: string;
  mandatory: boolean;
}

export interface Result {
  status: ResultStatus;
  message: string;
}

export interface Slot {
  bookingId: number;
  startTime: string;
  endTime: string;
  resource: Resource;
  serviceName: string;
  serviceType: ServiceType;
  reservationGroup: number;
  callText: string;
}

export interface Slots {
  slots: Slot[],
  hasMoreHits: boolean
}

export interface GroupedSlots {
  slots: Record<string, Slot[]>,
  hasMoreHits: boolean
}

export interface ServiceType {
  name: string;
  color: string;
  time: number;
  id: number;
  videoConsultation: boolean;
}

export interface Resource {
  id: number;
  civicRegNumber: string;
  name: string;
  aliasTo: number;
  occupation: Occupation;
  careUnit: CareUnit;
  hsaId: string;
}

export interface Occupation {
  id: number;
  name: string;
  shortName: string;
  visible: boolean;
}

export interface CareUnit {
  id: number;
  name: string;
  careUnitType: CareUnitType;
  postalAddress: string;
  streetAddress: string;
  city: string;
  parent: number;
  hsaId: string;
}

export interface Service {
  id: string;
  name: string;
  subContractId: string;
  fixedPriceIndicator: string;
  hourPrice: number;
  billableTimeSpan: number;
  realTimeSpan: number;
  paymentMethod?: PaymentMethod;
}

export interface ApiClient {
  id: number;
  name: string;
  username: string;
  bookingCompanyId: number;
  bookingPaymentMethod: number;
  bookingServiceId: number;
  bookingServiceCategoryId: number;
  bookingTypeId: number;
  bookingCreatePatient: number;
  bookingCancelHoursBefore: number;
  disabled: boolean;
  serviceTypes: ServiceType[];
}

export enum ResultStatus {
  OK = "OK",
  OK_BUT = "OK_BUT",
  FAIL = "FAIL",
  ERROR = "ERROR",
}

export enum PaymentMethod {
  VOLUME = "VOLUME",
  CASH = "CASH",
  SUBSCRIPTION = "SUBSCRIPTION",
  EXTRA_SERVICES = "EXTRA_SERVICES",
}

export enum CareUnitType {
  ORGANIZATION = "Organization",
  CARE_UNIT = "CareUnit",
  PART_OF_CARE_UNIT = "PartOfCareUnit",
}

export enum FieldName {
  firstname = "firstname",
  lastname = "lastname",
  email = "email",
  email_check = "email-check",
  phone = "phone",
  cellphone = "cellphone",
  boss = "boss",
  address = "address",
  billing_address = "billing_address",
  postnr = "postnr",
  city = "city",
  department = "department",
  additionalInfo = "additional-info"
}

export enum AuthType {
  FED = "FED",
  PLAIN = "PLAIN"
}

export enum NotificationType {
  EMAIL = "EMAIL",
  SMS = "SMS"
}

export enum ContactMethodType {
  PHONE_SWITCHBOARD = "PHONE_SWITCHBOARD",
  FAX = "FAX",
  URL = "URL",
  PHONE_WORK = "PHONE_WORK",
  PHONE_HOME = "PHONE_HOME",
  PHONE_MOBILE = "PHONE_MOBILE",
  PAGER = "PAGER",
  EMAIL = "EMAIL"
}