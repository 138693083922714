import React, { useEffect, useState } from "react";

import { fetchApplicationInfo } from "../../api/applicationInfo";
import { ApplicationInfo } from "../../api/applicationInfoTypes";
import { Box } from "grommet";
import styled from "styled-components";

interface Props {

}

const VersionBox = styled(Box)`
  align-items: left;
  max-width: 100%;
  min-width: 100%;
  font-size: 14px;
`;

const Version: React.FC<Props> = () => {
  const [appInfo, setAppInfo] = useState({
    version: '',
    testMode: false
  });

  useEffect(() => {
    fetchApplicationInfo().then((result: ApplicationInfo) =>
      setAppInfo(result)
    );
  }, []);


  return <VersionBox>{appInfo.version || ''}</VersionBox>
}

export default Version;