import * as moment from "moment";
import {
  ApiClient,
  Booking,
  CareUnit,
  CompanySettings,
  ExtraFieldResult,
  GroupedSlots,
  Patient,
  PaymentMethod,
  Service,
  ServiceType,
  Slot,
} from "../../api/bookingTypes";
import { PortalSettings } from "../../api/portalSettingsTypes";

export interface BookingState {
  clientId?: string;
  apiClient?: ApiClient;
  careUnits: CareUnit[];
  services: Service[];
  portalSettings: PortalSettings;
  filter: SearchFilter;
  availableSlots?: Record<string, Slot[]>;
  hasMoreHits: boolean;
  selectedSlot?: Slot;
  selectedService?: Service;
  patientDetails?: Patient;
  extraFieldResults: ExtraFieldResult[];
  page: BookingPage;
  booking?: Booking;
  bookingId?: string;
  companySettings?: CompanySettings;
}

export interface SearchFilter {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  service?: SelectServiceOption;
  resources: SelectOption[];
  careUnits: SelectOption[];
  earliestStartAt?: SelectOption;
}

export interface SelectOption {
  label: string;
  value: number;
}

export interface SelectServiceOption {
  label: string;
  value: string;
  id: string;
  name: string;
  subContractId: string;
  paymentMethod?: PaymentMethod;
  duration: number;
}

export interface PatientFormFieldData {
  label: string;
  name: string;
  value?: string;
  mandatory: boolean;
  invalid: boolean;
  errorMessage: string;
}

export enum BookingPage {
  SEARCH_SLOTS = "SEARCH_SLOTS",
  SELECT_SLOT = "SELECT_SLOT",
  PATIENT_DETAILS = "PATIENT_DETAILS",
  BOOK = "BOOK",
}

export interface SetApiClientAction {
  type: BookingTypeKeys.SET_API_CLIENT;
  apiClient: ApiClient;
}

export interface SetClientIdAction {
  type: BookingTypeKeys.SET_CLIENT_ID;
  clientId: string;
}

export interface SetBookingIdAction {
  type: BookingTypeKeys.SET_BOOKING_ID;
  bookingId: string;
}

export interface SetCareUnitsAction {
  type: BookingTypeKeys.SET_CARE_UNITS;
  careUnits: CareUnit[];
}

export interface SetServicesAction {
  type: BookingTypeKeys.SET_SERVICES;
  services: Service[];
}

export interface SetServiceTypesAction {
  type: BookingTypeKeys.SET_SERVICE_TYPES;
  serviceTypes: ServiceType[];
}

export interface SetPortalSettingsAction {
  type: BookingTypeKeys.SET_PORTAL_SETTINGS;
  portalSettings: PortalSettings;
}

export interface SetPatientAction {
  type: BookingTypeKeys.SET_PATIENT;
  patient: Patient;
}

export interface ClearPatientAction {
  type: BookingTypeKeys.CLEAR_PATIENT;
}

export interface SetPageAction {
  type: BookingTypeKeys.SET_PAGE;
  page: BookingPage;
}

export interface SetSearchFilterAction {
  type: BookingTypeKeys.SET_SEARCH_FILTER;
  filter: SearchFilter;
}

export interface ClearSearchFilterAction {
  type: BookingTypeKeys.CLEAR_SEARCH_FILTER;
}

export interface SetAvailableSlotsAction {
  type: BookingTypeKeys.SET_AVAILABLE_SLOTS;
  groupedSlots: GroupedSlots;
}

export interface ClearAvailableSlotsAction {
  type: BookingTypeKeys.CLEAR_AVAILABLE_SLOTS;
}

export interface SetSelectedSlotAction {
  type: BookingTypeKeys.SET_SELECTED_SLOT;
  slot: Slot;
}

export interface SetBookingAction {
  type: BookingTypeKeys.SET_BOOKING;
  booking: Booking;
}

export interface SetCompanySettingsAction {
  type: BookingTypeKeys.SET_COMPANY_SETTINGS;
  companySettings: CompanySettings;
}

export interface SetExtraFieldResultsAction {
  type: BookingTypeKeys.SET_EXTRA_FIELD_RESULTS;
  extraFieldResults: ExtraFieldResult[];
}

export interface ClearBookingAction {
  type: BookingTypeKeys.CLEAR_BOOKING;
}

export type BookingAction =
  | SetApiClientAction
  | SetClientIdAction
  | SetBookingIdAction
  | SetCareUnitsAction
  | SetServicesAction
  | SetServiceTypesAction
  | SetPortalSettingsAction
  | SetPatientAction
  | ClearPatientAction
  | SetPageAction
  | SetSearchFilterAction
  | ClearSearchFilterAction
  | SetAvailableSlotsAction
  | ClearAvailableSlotsAction
  | SetSelectedSlotAction
  | SetBookingAction
  | SetCompanySettingsAction
  | SetExtraFieldResultsAction
  | ClearBookingAction

export enum BookingTypeKeys {
  SET_API_CLIENT = 'SET_API_CLIENT',
  SET_CLIENT_ID = 'SET_CLIENT_ID',
  SET_BOOKING_ID = 'SET_BOOKING_ID',
  SET_CARE_UNITS = 'SET_CARE_UNITS',
  SET_SERVICES = 'SET_SERVICES',
  SET_SERVICE_TYPES = 'SET_SERVICE_TYPES',
  SET_PORTAL_SETTINGS = 'SET_PORTAL_SETTINGS',
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_PATIENT = 'SET_PATIENT',
  CLEAR_PATIENT = 'CLEAR_PATIENT',
  SET_PAGE = 'SET_PAGE',
  SET_SEARCH_FILTER = 'SET_SEARCH_FILTER',
  CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER',
  SET_AVAILABLE_SLOTS = 'SET_AVAILABLE_SLOTS',
  CLEAR_AVAILABLE_SLOTS = 'CLEAR_AVAILABLE_SLOTS',
  SET_SELECTED_SLOT = 'SET_SELECTED_SLOT',
  SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE',
  SET_BOOKING = 'SET_BOOKING',
  SET_EXTRA_FIELD_RESULTS = 'SET_EXTRA_FIELD_RESULTS',
  CLEAR_BOOKING = 'CLEAR_BOOKING',
  SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS',
}